import React, { useState } from "react";
import "./Capsule.css"; // Import the updated CSS

const CapsuleWidget = ({ iconSrc, text, onClick }) => {
  const [isSelected, setIsSelected] = useState(false); // Track selection state

  // Toggle selection on click
  const handleClick = () => {
    setIsSelected((prevSelected) => !prevSelected); // Toggle selection state
    onClick(text); // Call the passed function on click, passing the widget text
  };

  return (
    <div
      className={`capsule-widget ${isSelected ? "selected" : ""}`}
      onClick={handleClick}
    >
      <img src={iconSrc} alt="icon" className="capsule-icon" />
      <span className="capsule-text">{text}</span>
    </div>
  );
};

export default CapsuleWidget;
