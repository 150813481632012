import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Popup from '../components/Popup';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  RedditShareButton,
  EmailShareButton,
  TelegramShareButton,
  FacebookMessengerShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  RedditIcon,
  EmailIcon,
  TelegramIcon,
  FacebookMessengerIcon,
} from 'react-share';
import {supportedLanguages} from '../Constants'
import CapsuleWidget from './capsule/CapsuleWidget'

const backgroundMusicUrl = 'https://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Kangaroo_MusiQue_-_The_Neverwritten_Role_Playing_Game.mp3';

//const categories = ['General Knowledge','History', 'Geography', 'Science', 'Sports', 'Bollywood', 'Hollywood', 'Indian Cricket'];
const categories = [
  { name: 'General Knowledge', icon: '/icons/knowledge.png' },
  { name: 'History', icon: '/icons/parchment.png' },
  { name: 'Geography', icon: '/icons/mountain.png' },
  { name: 'Science', icon: '/icons/atom.png' },
  { name: 'Sports', icon: '/icons/sports.png' },
  { name: 'Bollywood', icon: '/icons/bollywood.png' },
  { name: 'Hollywood', icon: '/icons/red-carpet.png' },
  { name: 'Indian Cricket', icon: '/icons/cricket-helmet.png' },
];
let numOfQuestionsAnsweredPerGame;

const GameRoom = ({ socket }) => {
  const { roomId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const playerName = location.state?.playerName || '';  // Default to an empty string if playerName is undefined
  const [numQuestions, setNumQuestions] = useState(10);
  const [timePerQuestion, setTimePerQuestion] = useState(30);
  const [gameStarted, setGameStarted] = useState(false);
  const [question, setQuestion] = useState('');
  const [answers, setAnswers] = useState([]);
  const [scores, setScores] = useState({});
  const [players, setPlayers] = useState({});
  const [shareableLink, setShareableLink] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [remainingTime, setRemainingTime] = useState(timePerQuestion);  // New state variable for remaining time
  const [selectedAnswer, setSelectedAnswer] = useState(null);  // State to store the selected answer
  const [isCorrect, setIsCorrect] = useState(null);  // State to track if the selected answer is correct
  //declarations for popup with scores
  const [showPopup, setShowPopup] = useState(false);
  const [correctAnswerIndex, setCorrectAnswerIndex] = useState(null);
  const [sortedScores, setSortedScores] = useState([]);
  const [gamesPlayed, setGamesPlayed] = useState(0);
  const selectedLanguage = location.state?.selectedLanguage;

  const audioRef = useRef(null);

  useEffect(() => {
    // Check if playerName is valid before emitting joinRoom event
    if (playerName.trim() !== '') {
      socket.emit('joinRoom', { roomId, playerName, selectedLanguage });
    } else {
      navigate('/', { state: { roomId } });  // Redirect to the home page if no valid player name is found
    }

    const handleBackPress = () => {
      console.log("backbutton pressed");
      socket.disconnect(); // Disconnect the socket on back button press
      navigate('/');
      socket.connect(); // Redirect to the home page
    };

    window.addEventListener('popstate', handleBackPress); // Add event listener for back button press

    socket.on('playersUpdated', (updatedPlayers) => {
      setPlayers(updatedPlayers);
    });

    socket.on('gameStarted', (settings) => {
      setNumQuestions(settings.numQuestions);
      setTimePerQuestion(settings.timePerQuestion);
      setRemainingTime(settings.timePerQuestion);
      setGameStarted(true);
    });

    socket.on('newQuestion', (newQuestion) => {
      setQuestion(newQuestion);
      setAnswers([]);
      setRemainingTime(timePerQuestion);  // Reset remaining time when a new question is received
      setSelectedAnswer(null);  // Reset selected answer
      setIsCorrect(null);  // Reset correctness
    });

    socket.on('answerSubmitted', (data) => {
      setAnswers((prevAnswers) => [...prevAnswers, data]);
    });

    socket.on('currentScores', (updatedScores) => {
      setScores(updatedScores);
    });

    socket.on('gameEnded', (finalScores) => {
      setScores(finalScores);
      setGameStarted(false);
    });

    socket.on('currentQuestion', ({ question, remainingTime }) => {
      setGameStarted(true);
      setQuestion(question);
      setRemainingTime(remainingTime);  // Set the remaining time received from the server
      setSelectedAnswer(null);  // Reset selected answer
      setIsCorrect(null);  // Reset correctness
    });

    socket.on('showScoresAndAnswer', ({ correctAnswerIndex, sortedScores }) => {
      setCorrectAnswerIndex(correctAnswerIndex);
      setSortedScores(sortedScores);
      setShowPopup(true);

      // Automatically hide the popup after 5 seconds
      setTimeout(() => {
        setShowPopup(false);
      }, 5000);
    });

    return () => {
      socket.off('playersUpdated');
      socket.off('gameStarted');
      socket.off('newQuestion');
      socket.off('answerSubmitted');
      socket.off('gameEnded');
      socket.off('currentScores');
      socket.off('showScoresAndAnswer');
    };
  }, [roomId, socket, playerName, navigate, timePerQuestion, selectedLanguage]);

  useEffect(() => {
    const link = `${window.location.origin}/game/${roomId}`;
    setShareableLink(link);
  }, [roomId]);

  useEffect(() => {
    // Track pageview for Google Analytics.
    // Room page is visited if room id changes or if game has ended.
    if(gameStarted == false) {
      window.gtag('event', 'page_view', {
        page_path: location.pathname,
        page_title: 'Room_page',
      });
    }
    numOfQuestionsAnsweredPerGame = 0;
  }, [roomId, gameStarted]);

  // Countdown timer effect
  useEffect(() => {
    if (gameStarted && question) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            clearInterval(timer);
            return 0;
          }
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [gameStarted, question]);

  const startGame = () => {
    socket.emit('startGame', roomId, { numQuestions, timePerQuestion, selectedCategories, selectedLanguage });
    setGamesPlayed((prev) => prev + 1);
    // Track when a game starts
    window.gtag('event', 'start_game', {
      event_category: 'Game',
      event_label: `Game Started in Room`,
      value: gamesPlayed + 1, // Include how many times the game has started in this room
    });
    window.gtag('event', 'start_game_players', {
      event_category: 'Game',
      event_label: `Game Started with players number`,
      value: Object.keys(players).length, // Include how many players played this game
    });
    selectedCategories.forEach((category) => {
      window.gtag('event', 'start_game_category', {
        event_category: 'Game',
        event_label: `Game Started with category`,
        value: category,
      });
    })
  };

  const submitAnswer = (optionIndex) => {
    const option = question.options[optionIndex];
    setSelectedAnswer(option);  // Set the selected answer
    const correctAnswer = question.correctAnswer;
    setIsCorrect(option === correctAnswer);  // Check if the selected answer is correct
    socket.emit('submitAnswer', roomId, optionIndex);

    numOfQuestionsAnsweredPerGame = numOfQuestionsAnsweredPerGame+1;
    window.gtag('event', 'question_answered', {
      event_category: 'Game',
      event_label: `Question answered in Room: ${roomId}`,
      value: numOfQuestionsAnsweredPerGame,
    });
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(category)
        ? prevSelected.filter((cat) => cat !== category)
        : [...prevSelected, category]
    );
  };

  const handleTimePerQuestionChange = (e) => {
    const timeValue = parseInt(e, 10);
    setTimePerQuestion(timeValue);
    setRemainingTime(timeValue);
  };


      const renderScores = () => {
        const sortedScores = Object.keys(scores)
          .map((playerId) => ({
            playerId,
            name: players[playerId]?.name,
            ...scores[playerId],
          }))
          .sort((a, b) => b.correct - a.correct || a.time - b.time);
      
        return (
          <div style={{display: "flex", flexDirection: "column"}}>
          <table style={{ width: "90%", borderCollapse: "collapse", color: "#ffffff", alignSelf: "center"}}>
            <thead>
              <tr>
                <th style={{ border: "1px solid #fff", padding: "10px", color: "#ffcc00" }}>Rank</th>
                <th style={{ border: "1px solid #fff", padding: "10px", color: "#ffcc00" }}>Name</th>
                <th style={{ border: "1px solid #fff", padding: "10px", color: "#ffcc00" }}>Correct</th>
                <th style={{ border: "1px solid #fff", padding: "10px", color: "#ffcc00" }}>Time (s)</th>
              </tr>
            </thead>
            <tbody>
              {sortedScores.map((player, index) => (
                <tr key={player.playerId} style={{ backgroundColor: "transparent", borderBottom: "1px solid #fff" }}>
                  <td style={{ textAlign: "center", padding: "8px", border: "1px solid #fff" }}>
                    {index + 1}
                  </td>
                  <td style={{ textAlign: "center", padding: "8px", border: "1px solid #fff" }}>
                    {player.name}
                  </td>
                  <td style={{ textAlign: "center", padding: "8px", border: "1px solid #fff" }}>
                    {player.correct}
                  </td>
                  <td style={{ textAlign: "center", padding: "8px", border: "1px solid #fff" }}>
                    {player.time.toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        );
      };
      
  
  

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(shareableLink);
    alert('Game room link copied to clipboard!');
  };

  const getJoinedNames = () => {
    const playerArray = Object.values(players);
    return playerArray.map(player => player.name).join(', ');
  }

  const startGameButtonText = Object.keys(scores).length > 0 ? "Continue..." : "Start Game";

  return (
    <div className="game-room">
      <audio ref={audioRef} src={backgroundMusicUrl} loop autoPlay>
        Your browser does not support the audio element.
      </audio>
      <h1>Game Room: {roomId}</h1>
      {!gameStarted ? (
        <div className="config">
          {Object.keys(scores).length > 0 && (
            <>
              <h2>Final Scores:</h2>
              {renderScores()}
            </>
          )}
          <div>
          <h2>Players in the room:</h2>
          <ul>
            {getJoinedNames()}
          </ul>
          </div>
          <div>
          <label>
            Number of Questions:
            <input
              type="number"
              value={numQuestions}
              style={{ width: '60px' }} // Adjust the width as needed
              onChange={(e) => setNumQuestions(e.target.value)}
            />
          </label>
          </div>
          <label>
            Time per Question (seconds):
            <input
              type="number"
              value={timePerQuestion}
              style={{ width: '60px' }} // Adjust the width as needed
              onChange={(e) => handleTimePerQuestionChange(e.target.value)}
            />
          </label>
          <h3>Select Categories:</h3>
          <div className="categories">
            
            {categories.map((category) => (
              <CapsuleWidget
              iconSrc={category.icon} // Icon image URL (can be replaced)
              text={category.name}
              onClick={handleCategoryChange}
            />
            ))}
          </div>
          <div>
          <button onClick={startGame}>{startGameButtonText}</button>
          </div>
          <div className="share-link">
            <h2>Share this game room through:</h2>

            {/* Social media share buttons */}
            <div className="social-share-buttons">
              <WhatsappShareButton url={shareableLink} title="Ready for a Fun Quiz battle? Join My Quiz Game!">
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>

              <FacebookMessengerShareButton url={shareableLink} title="Ready for a Fun Quiz battle? Join My Quiz Game!">
                <FacebookMessengerIcon size={32} round />
              </FacebookMessengerShareButton>

              <TelegramShareButton url={shareableLink} title="Ready for a Fun Quiz battle? Join My Quiz Game!">
                <TelegramIcon size={32} round />
              </TelegramShareButton>

              <FacebookShareButton url={shareableLink} title="Ready for a Fun Quiz battle? Join My Quiz Game!">
                <FacebookIcon size={32} round />
              </FacebookShareButton>

              <TwitterShareButton url={shareableLink} title="Ready for a Fun Quiz battle? Join My Quiz Game!">
                <TwitterIcon size={32} round />
              </TwitterShareButton>

              <LinkedinShareButton url={shareableLink} title="Ready for a Fun Quiz battle? Join My Quiz Game!">
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>

              <RedditShareButton url={shareableLink} title="Ready for a Fun Quiz battle? Join My Quiz Game!">
                <RedditIcon size={32} round />
              </RedditShareButton>

              <EmailShareButton url={shareableLink} title="Ready for a Fun Quiz battle? Join My Quiz Game!">
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>
            <h3>Or share this link manually with friends:</h3>
            <input type="text" value={shareableLink} readOnly />
            <button onClick={copyLinkToClipboard}>Copy Link</button>
          </div>
        </div>
      ) : (
        <div className="game">
          {question && (
            <>
              <h2>Question {question.questionIndexInGame} of {numQuestions} </h2>
              <div className="question-container">
                <h2 className="question-boundary">{question.question}</h2>
              </div>
              {question.type === 'image' && (
                <img src={question.imageUrl} alt="Question" style={{ maxWidth: '100%', height: 'auto' }} />
              )}
              <div className="options-container">
                <div className="options-row">
                  <button
                    onClick={() => submitAnswer(0)}
                    disabled={!!answers.find(a => a.playerId === socket.id) || selectedAnswer !== null}
                    className={`option-boundary ${selectedAnswer === question.options[0]
                      ? isCorrect
                        ? 'correct-answer'
                        : 'wrong-answer'
                      : ''}`}
                  >
                    {question.options[0]}
                  </button>
                  <button
                    onClick={() => submitAnswer(1)}
                    disabled={!!answers.find(a => a.playerId === socket.id) || selectedAnswer !== null}
                    className={`option-boundary ${selectedAnswer === question.options[1]
                      ? isCorrect
                        ? 'correct-answer'
                        : 'wrong-answer'
                      : ''}`}
                  >
                    {question.options[1]}
                  </button>
                </div>
                <div className="options-row">
                  <button
                    onClick={() => submitAnswer(2)}
                    disabled={!!answers.find(a => a.playerId === socket.id) || selectedAnswer !== null}
                    className={`option-boundary ${selectedAnswer === question.options[2]
                      ? isCorrect
                        ? 'correct-answer'
                        : 'wrong-answer'
                      : ''}`}
                  >
                    {question.options[2]}
                  </button>
                  <button
                    onClick={() => submitAnswer(3)}
                    disabled={!!answers.find(a => a.playerId === socket.id) || selectedAnswer !== null}
                    className={`option-boundary ${selectedAnswer === question.options[3]
                      ? isCorrect
                        ? 'correct-answer'
                        : 'wrong-answer'
                      : ''}`}
                  >
                    {question.options[3]}
                  </button>
                </div>
              </div>

              <h3>Time Remaining: {remainingTime.toFixed(0)} seconds</h3> {/* Display remaining time */}
            </>
          )}
          {Object.keys(scores).length > 0 && <div className="scores">
            <h2>Scores:</h2>
            {renderScores()}
          </div>}
          
        </div>
      )}
      {showPopup && (
        <Popup
          correctAnswer={question.options[correctAnswerIndex]}
          playerScores={sortedScores}
        />
      )}
    </div>
  );
};

export default GameRoom;
